import * as yup from 'yup';

//model
import model from './model';

const {
	formField: { username, password, confirmPassword, role },
} = model;

const validation = yup.object().shape({
	[username.name]: yup
		.string()
		.min(1)
		.required(`${username.requireErrorMsg}`),
	[password.name]: yup.string().min(8, 'Your password is too short.').required(`${password.requireErrorMsg}`),
    [confirmPassword.name]: yup
    .string()
    .required(`${confirmPassword.requireErrorMsg}`)
    .oneOf([yup.ref('password')], 'Your passwords do not match.'),
    [role.name]: yup.string().oneOf(['admin', 'operator']).required(`${role.requireErrorMsg}`),
});

export default validation;