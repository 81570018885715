
import { useEffect } from 'react';
import { Form, Formik } from 'formik';
import UtilsForm from 'src/utils/form';
import { Link, useNavigate } from 'react-router-dom';
import toast from 'react-hot-toast';

import { useAppDispatch, useAppSelector } from 'src/app/store';
import { useLoginMutation } from 'src/features/auth/authApiSlice';
import { setCredentials, selectCurrentUser } from 'src/features/auth/authSlice';

import model from 'src/components/form/login/model';
import Holder from 'src/components/form/login/holder';
import validation from 'src/components/form/login/validation';
import { GlobalTypes } from 'src/utils/types';

import myMoon from "src/textures/my-moon-login.png"
import Spinner from 'src/components/shared/spinner';

const Login: React.FC = () => {
	const { formId, formField }: GlobalTypes.Model = model;
	const navigate = useNavigate();

	const [login, { isLoading, isSuccess }] = useLoginMutation();
	const dispatch = useAppDispatch();
	const user = useAppSelector(selectCurrentUser);

	useEffect(() => {
		if (user.username !== '' && user.id !== '' && user.role !== '') {
			navigate('/dashboard');
		}
	}, [user, navigate]);

	const handleSubmit = async (
		values: GlobalTypes.LoginInitialValues,
		actions: any
	) => {
		const { username, password } = values;
		try {
			const userData = await login({ username, password }).unwrap();
			dispatch(
				setCredentials({ ...userData, ...userData})
			);
			localStorage.setItem('token', userData?.token)
			toast.success('Login Successful!');
			navigate('/dashboard');
		} catch (err: any) {
			console.log('ERR', err);
			if (!err?.status) {
				toast.error('No Server Response');
			} else if (err?.status === 401) {
				toast.error("Wrong Username or Password");
			} else if (err?.status === 403) {
				toast.error(err.data.message);
			} else {
				toast.error('Login Failed');
			}
		}
	};

	const content = isLoading && !isSuccess ? (
		<div className='d-flex align-items-center justify-center vh-100'>
			<Spinner size="lg"/>
		</div>
	) : (
		<section className="login-bg">
			<div className="container">
				<div className="row">
					<div className="col-lg-12">
						<div className='centered-item'>
							<div className="form-container login">
								<div className='login-logo-container'>
									<img className='login-logo' src={myMoon} alt="my-moon-logo" />
								</div>
								<Formik
									initialValues={UtilsForm.IntialValuesFromModel(model)}
									validationSchema={validation}
									onSubmit={handleSubmit}
								>
									{({ isSubmitting, dirty, isValid, errors, touched, values }) => (
										<Form id={formId} className="mb-4">
											<Holder
												formField={formField}
												values={values}
												errors={errors}
												touched={touched}
											/>

											<button
												type="submit"
												disabled={isSubmitting || !(isValid && dirty)}
												className={`${
													(isSubmitting || !(isValid && dirty)) && 'btn-outline-light'
												} btn btn-primary btn-block mb-1 full-width`}
											>
												Login
											</button>
										</Form>
									)}
								</Formik>
								<Link className='forgot-password' to="/recovery-password">
									<p>Forgot Password</p>
								</Link>
							</div>
						</div>
					</div>
				</div>
			</div>
		</section>
	);

	return content;
};

export default Login;
