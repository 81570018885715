import { createSlice } from '@reduxjs/toolkit';
import { RootState } from '../../app/store';

const usersSlice = createSlice({
	name: 'users',
	initialState: {
		allUsers: [],
		detailUser: {
            id: "",
			username: "",
			status: "",
			role: "",
			createdAt: "",
			updatedAt: "",
			createdBy: "",
			updatedBy: "",
			createdByUsername: "",
			updatedByUsername: ""
        },
	},
	reducers: {
		setAllUsers: (state, action) => {
			state.allUsers = action.payload;
		},
		setDetailUser: (state, action) => {
			state.detailUser = {...action.payload};
		},
	},
});

export const { setAllUsers, setDetailUser } = usersSlice.actions;

export default usersSlice.reducer;

export const selectAllUsers = (state: RootState) => state.users.allUsers;
export const selectDetailUser = (state: RootState) => state.users.detailUser;