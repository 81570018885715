import { apiSlice } from 'src/app/api/apiSlice';

export const authApiSlice = apiSlice.injectEndpoints({
	endpoints: (builder) => ({
		login: builder.mutation({
			query: (credentials) => ({
				url: '/auth/login',
				method: 'POST',
				body: { ...credentials },
				mode: 'cors',
			}),
		}),
		change: builder.mutation({
			query: (body) => ({
				url: `/auth/change-password`,
				method: 'PUT',
				body: { ...body },
				mode: 'cors',
			}),
		}),
	}),
});

export const {
	useLoginMutation, // auto gen from authApiSlice
	useChangeMutation,
} = authApiSlice;
