

const Dashboard: React.FC = () => {
    return (
        <div className="general-side-p">
            Dashboard
        </div>
    )
}

export default Dashboard