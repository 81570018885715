import { apiSlice } from '../../app/api/apiSlice';


export const vpnAccountsApiSlice = apiSlice.injectEndpoints({
	endpoints: (builder) => ({
		getAllAccounts: builder.query({
			query: ({ page = 1, ...params }) => ({
				url: `/vpns/accounts?page=${page}`,
				method: 'GET',
				params: { ...params },
				mode: 'cors',
			}),
			providesTags: (result) => 
			result
			? [
				...result.map(({ id }: {id: string|number}) => ({
					type: 'VpnAccounts' as const,
					id,
				})),
				{ type: 'VpnAccounts', id: 'LIST' },
			  ]
          	: [{ type: 'VpnAccounts', id: 'LIST' }],
			keepUnusedDataFor: 5,
		}),
		getAllAccountsUsers: builder.query({
			query: ({ page = 1, ...params }) => ({
				url: `/vpns/users?page=${page}`,
				method: 'GET',
				params: { ...params },
				mode: 'cors',
			}),
			providesTags: (result) =>
			result
			? [
				...result.map(({ id }: {id: string|number}) => ({
					type: 'VpnAccountsUsers' as const,
					id,
				})),
				{ type: 'VpnAccountsUsers', id: 'LISTUSERS' },
			  ]
          	: [{ type: 'VpnAccountsUsers', id: 'LISTUSERS' }]
			,
			keepUnusedDataFor: 5,
		}),
		getAllAccountsUsersCount: builder.query({
			query: ({...params}) => ({
				url: `/vpns/users/count`,
				method: 'GET',
				params: {...params},
				mode: 'cors',
			}),
		}),
		getAllPlans: builder.query({
			query: ({page = 1, ...params}) => ({
				url: `/vpns/accounts/plans?page=${page}`,
				method: 'GET',
				params: {...params},
				mode: 'cors',
			}),
			providesTags: (result, error, id) => [{type: 'VpnAccountsPlans', id}],
			keepUnusedDataFor: 5,
		}),
		editAccountStatus: builder.mutation({
			query: ({id, status}) => ({
				url: `/vpns/accounts/${id}/status/${status}`,
				method: 'PUT',
			}),
			invalidatesTags: (result, error, { id }) =>
			result
				? [
					{ type: 'VpnAccounts', id },
					{ type: 'VpnAccounts', id: 'LIST' },
				  ]
				: [{ type: 'VpnAccounts', id: 'LIST' }],
		}),
		deleteAccount: builder.mutation({
			query: (userId) => ({
				url: `/vpns/accounts/${userId}`,
				method: 'DELETE',
				body: userId
			}),
			invalidatesTags: [{type:'VpnAccounts', id: 'LIST'}]
		})
	}),
});

export const {
	useGetAllAccountsQuery,
	useGetAllAccountsUsersQuery,
	useLazyGetAllAccountsUsersCountQuery, // wenever we wanna trigger a call on a click or inside a useEffect we can use this query instead
	useGetAllAccountsUsersCountQuery,
	useGetAllPlansQuery,
	useEditAccountStatusMutation,
	useDeleteAccountMutation,
} = vpnAccountsApiSlice;
