import { useLocation, useNavigate, Routes, Route } from 'react-router-dom';
import { useEffect } from 'react';
import Layout from './components/layout/Layout';
import RequireAuth from './components/shared/guardRoute';
import Login from './view/Login';
import VpnAccountsList from './view/VpnAccountsList';
import VpnAccountUserDetail from './view/VpnAccountUserDetail';
import BackofficeUsersList from './view/BackofficeUsers';
import Dashboard from './view/Dashboard';
import NotFound from './view/NotFound';

function App() {
	const location = useLocation();
	const navigate = useNavigate();

	useEffect(() => {
		if (location.pathname === '/') {
			navigate('/login');
		}
	}, [location, navigate]);

	return (
		<Routes>
			<Route index path="/login" element={<Login />} />

			<Route path="/" element={<Layout />}>
				<Route element={<RequireAuth />}>
					<Route path="dashboard" element={<Dashboard />} />
					<Route path="users" element={<BackofficeUsersList />} />
					<Route path="vpns/accounts" element={<VpnAccountsList />} />
					<Route path="vpns/accounts/user/:id" element={<VpnAccountUserDetail />} />
				</Route>
			</Route>

			<Route path="*" element={<NotFound />} />
		</Routes>
	);
}

export default App;
