import { Link } from "react-router-dom";

const NotFound = () => {
    return (
        <div className="not-found">
            <div className="text-center">
                <h1>404 Not Found</h1>
                <h3>Oops! You seem to be lost.</h3>
                <p>Here are some helpful links:</p>
                <ul>
                    <li>
                        <Link to='/login'>Login</Link> 
                    </li>
                    <li>
                        <Link to='/dashboard'>Dashboard</Link> 
                    </li>
                    <li>
                        <Link to='/users'>Admin Users List</Link>
                    </li>
                </ul>
            </div>
        </div>
    )
}

export default NotFound