import React, {useEffect} from "react"
import { Form, Formik } from 'formik';
import UtilsForm from 'src/utils/form';
import toast from 'react-hot-toast';

import { useChangeMutation } from 'src/features/auth/authApiSlice';

import model from 'src/components/form/changePassword/model';
import Holder from 'src/components/form/changePassword/holder';
import validation from 'src/components/form/changePassword/validation';
import { GlobalTypes } from 'src/utils/types';
import Spinner from "src/components/shared/spinner";

interface CPProps {
    handleClose: () => void;
}

const ChangePassword: React.FC<CPProps> = ({handleClose}) => {
	const { formId, formField }: GlobalTypes.Model = model;
	
	const [change, { isLoading, isSuccess, error, isError }] = useChangeMutation();

	const handleSubmit = async (
		values: GlobalTypes.ChangePasswordInitialValues,
		actions: any
	) => {
		try {
			await change(values)
			handleClose()
		} catch (err: any) {
			console.log('ERR', err);
			if (!err?.status) {
				toast.error('No Server Response');
			} else if (err?.status === 401) {
				toast.error(err.data.message);
			} else if (err?.status === 403) {
				toast.error(err.data.message);
			} else {
				toast.error('Action Failed');
			}
		}
	};

	useEffect(() => {
        if(isSuccess) {
            toast.success("Password changed successfully")
        }
        if(isError && error !== undefined) {
			console.log("ERROR", error)
            //@ts-ignore
            toast.error(error.data.error)
        }

    }, [error, isError, isSuccess])

	const content = isLoading ? (
		<Spinner size="lg" />
	) : (
		<section className="container">
			<div className="row">
				<div className="col-lg-12">
                    <div className='general-side-p'>
						<div>
							<h4 className='title-modal'>Change password</h4>
						</div>
                        <div className="container-form">
                            <Formik
                                initialValues={UtilsForm.IntialValuesFromModel(model)}
                                validationSchema={validation}
                                onSubmit={handleSubmit}
                            >
                                {({ isSubmitting, dirty, isValid, errors, touched, values }) => (
                                    <Form id={formId}>
                                        <Holder
                                            formField={formField}
                                            values={values}
                                            errors={errors}
                                            touched={touched}
                                        />

                                        <button
                                            type="submit"
                                            disabled={!(isValid && dirty)}
                                            className={`mt-4 ${
                                                !(isValid && dirty) && 'btn-outline-light'
                                            } btn btn-primary btn-block mb-1 full-width`}
                                        >
                                            Save
                                        </button>
                                    </Form>
                                )}
                            </Formik>
                        </div>
                    </div>
				</div>
			</div>
		</section>
	);

	return content;
};

export default ChangePassword;