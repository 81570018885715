import React from 'react';
import { Field, FormikErrors, FormikTouched } from 'formik';
import { GlobalTypes } from 'src/utils/types';
import { BsFillPersonFill } from 'react-icons/bs';
import { HiLockClosed } from 'react-icons/hi';

interface HolderProps {
	errors: FormikErrors<{ [field: string]: any }>;
	values: object;
	touched: FormikTouched<{ [field: string]: any }>;
	formField: GlobalTypes.FormFieldModel;
}

const Holder: React.FC<HolderProps> = (props) => {
	const { errors, touched } = props;
	const {
		formField: { username, password },
	} = props;

	let usernameErr: string = errors.username as string;
	let passwordErr: string = errors.password as string;

	return (
		<>
			<div className="form-outline mb-4">
				<label className="form-label-login" htmlFor={username.name}>
					Username
				</label>
				<div className='login-input-container'>
					<BsFillPersonFill className="login-input-icon" />
					<div className='divider'>
					</div>
					<Field type="text" placeholder="Type your username" name={username.name} className="form-control login" />
				</div>
				{errors.username && touched.username ? (
					<div className="error-msg">{usernameErr}</div>
				) : null}
			</div>

			<div className="form-outline mb-4">
				<label className="form-label-login" htmlFor={password.name}>
					Password
				</label>
				<div className='login-input-container'>
					<HiLockClosed className="login-input-icon" />
					<div className='divider'>
					</div>
					<Field type="password" placeholder="Type your password" name={password.name} className="form-control login" />
				</div>
				{errors.password && touched.password ? (
					<div className="error-msg">{passwordErr}</div>
				) : null}
			</div>
		</>
	);
};

export default Holder;
