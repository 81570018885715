import { GlobalTypes } from './types';

const statusValues: GlobalTypes.SelectOptions[] = [
	{ label: 'All', value: '' },
	{ value: 'enabled', label: 'Enabled' },
	{ value: 'blocked', label: 'Blocked' },
	{ value: 'pending', label: 'Pending' },
];

const authTypeValues: GlobalTypes.SelectOptions[] = [
	{ label: 'All', value: '' },
	{ label: 'Password', value: 'password' },
	{ label: 'Anonymous', value: 'anonymous' },
	{ label: 'Google', value: 'google' },
	{ label: 'Apple', value: 'apple' },
];

const adminFilterRoleValues: GlobalTypes.SelectOptions[] = [
	{ label: 'All', value: '' },
	{ label: 'Admin', value: 'admin' },
	{ label: 'Operator', value: 'operator' },
];

const adminRoleValues: GlobalTypes.SelectOptions[] = [
	{ label: 'Admin', value: 'admin' },
	{ label: 'Operator', value: 'operator' },
];

const adminStatusValues: GlobalTypes.SelectOptions[] = [
	{ label: 'Enabled', value: 'enabled' },
	{ label: 'Disabled', value: 'disabled' },
	{ label: 'Blocked', value: 'blocked' },
	{ label: 'Pending', value: 'pending' },
];

export const Options = {
	statusValues,
	authTypeValues,
	adminRoleValues,
	adminFilterRoleValues,
	adminStatusValues
};
