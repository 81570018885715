import React from "react"
import { FaLongArrowAltUp, FaLongArrowAltDown } from 'react-icons/fa';

interface TableHeader {
	setSort: (key: string, ascending: boolean) => void;
	sorting: {
		key: string;
		ascending: boolean;
	}
}

const ListHeader: React.FC<TableHeader> = ({setSort, sorting}) => {

return (
		<thead>
			<tr>
				<th onClick={() => setSort('email', !sorting.ascending)}>Email|Anon-account {sorting.ascending && sorting.key === 'email' ? <FaLongArrowAltUp /> : <FaLongArrowAltDown />}</th>
				<th onClick={() => setSort('createdAt', !sorting.ascending)}>Created At {sorting.ascending && sorting.key === 'createdAt' ? <FaLongArrowAltUp /> : <FaLongArrowAltDown />}</th>
				<th onClick={() => setSort('status', !sorting.ascending)}>Status {sorting.ascending && sorting.key === 'status' ? <FaLongArrowAltUp /> : <FaLongArrowAltDown />}</th>
				<th>Auth type</th>
				<th>Actions</th>
			</tr>
		</thead>
	);
};

export default ListHeader;
