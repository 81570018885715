import {useState, useEffect, useRef} from "react"
import { Link, useLocation, useParams, useNavigate } from "react-router-dom";
import { useAppSelector } from 'src/app/store';
import { selectCurrentUser, logOut } from 'src/features/auth/authSlice';
import { RiArrowDownSLine } from 'react-icons/ri';
import { FaLongArrowAltLeft } from 'react-icons/fa';
import Modal from "../modal";
import ChangePassword from "src/components/common/ChangePassword";
import ResetPassword from "src/components/common/ResetPassword";
import { useAppDispatch } from "src/app/store";


const Adminbar = () => {
	const user = useAppSelector(selectCurrentUser);
	const [isOpen, setIsOpen] = useState(false)
	const [changePasswordOpen, setChangePasswordOpen] = useState(false)
	const [resetPasswordOpen, setResetPasswordOpen] = useState(false)
	const location = useLocation()
	const param = useParams()
	const navigate = useNavigate()
	const dispatch = useAppDispatch()

	const userName = user ? `${user['username']}` : 'Welcome';
	const userRole = user ? `${user['role']}` : 'admin';
	const userId = user && `${user['id']}`;

	const adminLinks = [
		{
			title: 'Reset Password',
			action: () => {
				setIsOpen(false)
				setResetPasswordOpen(true)
			}
		},
		{
			title: 'Change Password',
			action: () => {
				setIsOpen(false)
				setChangePasswordOpen(true)
			}
		},
	]

	const handleChangePasswordClose = () => {
		setChangePasswordOpen(false)
	}

	const handleResetPasswordClose = () => {
		setResetPasswordOpen(false)
	}

	const handleLogout = () => {
		dispatch(logOut())
		localStorage.removeItem('token')
		navigate("/login")
	}

	const ref = useRef<HTMLDivElement>(null)

	const renderHeaderTitle = () => {
		let text;

		if(location.pathname === "/users") {
			text = "Sub-Admin"
		}

		if(location.pathname === "/dashboard") {
			text = "Dashboard"
		}

		if(location.pathname === "/vpns/accounts") {
			text = "VPN Clients"
		}

		if(location.pathname === `/vpns/accounts/user/${param.id}`) {
			text = <div className="d-flex align-items-center justify-content-center">
						<Link to="/vpns/accounts">
							<button className="go-back-btn"><FaLongArrowAltLeft /></button>
						</Link> 
						VPN Client Detail
					</div>
		}

		if(location.pathname === "/activity-logs") {
			text = "Activity Logs"
		}

		if(location.pathname === "/feedback-management") {
			text = "Feedback Menagement"
		}

		return text
	}

    useEffect(() => {
      const handleClickOutside = (event: MouseEvent) => {
        if(ref.current && isOpen && !ref.current.contains(event.target as Node)){
			setIsOpen(false)
		  }
      };
      document.addEventListener("mousedown", handleClickOutside);

	  return () => {
		document.removeEventListener("mousedown", handleClickOutside);
   		};
    }, [ref, isOpen]);

	const content = (
		<div>
			<div className="admin-bar general-header-side-p">	
				<h4 className='page-title'>{renderHeaderTitle()}</h4>
				<div className='user-info-container'>
					<div ref={ref} className='user-info-container' onClick={() => setIsOpen(prev => !prev)}>
						<div className="d-flex align-items-center">
							<div className='user-image-container'>{userName.slice(0,1)}</div>		
							<div className='user-name-container'>
								<p className='user-name'>{userName}</p>
								<p className='user-role'>{userRole}</p>
							</div>
						</div>
						<RiArrowDownSLine className={`dropdown-arrow ${isOpen ? 'open' : 'close'}`} />
					</div>
					<div className={`admin-bar-dropdown ${isOpen ? 'open' : 'close'}`}>
						<ul className="admin-dropdonw-list">
							{adminLinks.map((item, index) => {
								return (
									
									<li key={index} className="admin-dropdown-item" onClick={() => {
										item.action()
									 }}>
										{item.title}
									</li>
									
								)
							})}
							<li className="admin-dropdown-item" onClick={() => { handleLogout() }}>
								Logout
							</li>
						</ul>
					</div>
				</div>
			</div>
			<Modal handleClose={handleChangePasswordClose} isOpen={changePasswordOpen}>
                <ChangePassword handleClose={handleChangePasswordClose} />
            </Modal>
			<Modal handleClose={handleResetPasswordClose} isOpen={resetPasswordOpen}>
                <ResetPassword handleClose={handleResetPasswordClose} id={userId} />
            </Modal>
		</div>
	);
	return content;
};

export default Adminbar;