import React, {useState, useEffect} from "react"
import ChangeAdminStatus from "src/components/form/changeUserStatus";
import Spinner from "src/components/shared/spinner";

import { useEditAccountStatusMutation } from 'src/features/vpnAccounts/vpnAccountsApiSlice';
import toast from "react-hot-toast";
import { setDetailAccountUserStatus } from "src/features/vpnAccounts/vpnAccountsSlice";
import { useAppDispatch } from "src/app/store"


interface CSProps {
    handleClose: () => void;
    id:string;
    currentStatus: string
}

const ChangeStatusAccount: React.FC<CSProps> = ({handleClose, id, currentStatus}) => {

    const [selectedStatus, setSelectedStatus] = useState('');

    const [status, {isLoading, isSuccess, isError, error}] = useEditAccountStatusMutation();
    const dispatch = useAppDispatch()

    const onStatusChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
		e.preventDefault();
		setSelectedStatus(e.target.value);
	};

    const onEditStatus = async () => {
        try {
            await status({id:id, status:selectedStatus})
            handleClose()
        } catch (err: any) {
			console.log('ERR', err);
			if (!err?.status) {
				toast.error('No Server Response');
			} else if (err?.status === 401) {
				toast.error("Unauthorized action");
			} else if (err?.status === 403) {
				toast.error(err.data.message);
			} else {
				toast.error('Create User Failed!');
			}
		}
    }

    useEffect(() => {
        console.log("running one")
        if(isSuccess) {
            dispatch(setDetailAccountUserStatus(selectedStatus))
            toast.success("Status changed successfully")
        }
    }, [isSuccess, dispatch, selectedStatus])

    useEffect(() => {
        console.log("running two")

        if(isError && error !== undefined) {
            //@ts-ignore
            toast.error(error.data.error)
        }
    }, [error, isError,])

    let content = null;

    if(id) {
       content = (
        <div className="full-body">
            <div className="container-full-width">
                <div>
                    <h4 className='title-modal'>Edit Account Status</h4>
                </div>
                <div className="change-sub-admin-status">
                    <span>Change Status</span>
                    <ChangeAdminStatus currentValue={currentStatus} onChange={onStatusChange} />
                    {<button disabled={(selectedStatus !== currentStatus && selectedStatus !== '') || !isLoading ? false : true} onClick={() => onEditStatus()} className={`edit-status-button ${selectedStatus !== currentStatus && selectedStatus !== '' ? "" : "disabled"}`}>{isLoading ? 'Processing...' : 'Save'}</button>}
                </div>
            </div>
        </div>
       )
    }

    if(!id) {
        return <Spinner size="lg" />
    }

    return content
}

export default ChangeStatusAccount