import { createSlice } from '@reduxjs/toolkit';
import { RootState } from 'src/app/store';
import jwt_decode, { JwtPayload } from "jwt-decode"; // has t be used inside setCredentials, becouse response from /login is just a jwt token and it needs to be decoded

//no more refresh token just username, role and token
const authSlice = createSlice({
	name: 'auth',
	initialState: {
		user: {id: '', username: '', role: ''},
		token: null,
		// refresh_token: null,
	},
	reducers: {
		setCredentials: (state, action) => {
			// console.log("received on login", action.payload)
			console.log("STATE" ,state)
			console.log("ACTION" ,action)
			const { token } = action.payload;
			const decoded: any = jwt_decode(token)

			// console.log("TOKEN", token)
			// console.log("DECODED TOKEN", decoded)
			// console.log('USER', user);
			// console.log('ACCESS', access_token);
			// console.log('REFRESH', refresh_token);
			
			state.user = {id: decoded.sub, username: decoded.username, role: decoded.role};
			state.token = token;
			
 					
			// state.refresh_token = refresh_token;
		},
		logOut: (state) => {
			state.user = {id: '', username: '', role: ''};
			state.token = null;
		},
	},
});

export const { setCredentials, logOut } = authSlice.actions;

export default authSlice.reducer;

export const selectCurrentUser = (state: RootState) => state.auth.user;
export const selectCurrentToken = (state: RootState) => state.auth.token;
export const selectCurrentId = (state: RootState) => state.auth.user.id;
