import React, {useState, useEffect} from "react"

import { Form, Formik } from 'formik';
import UtilsForm from 'src/utils/form';
import toast from 'react-hot-toast';

import { useCreateMutation } from "src/features/admin/usersApiSlice"

import model from 'src/components/form/create/model';
import Holder from 'src/components/form/create/holder';
import validation from 'src/components/form/create/validation';
import { GlobalTypes } from 'src/utils/types';
import Spinner from "src/components/shared/spinner";

interface CSAProps {
    handleClose: () => void;
}

const CreateSubAdmin: React.FC<CSAProps> = ({handleClose}) => {

    const { formId, formField }: GlobalTypes.Model = model;

	const [create, { isLoading, isSuccess, isError, error }] = useCreateMutation();

	const handleSubmit = async (
		values: GlobalTypes.AdminInitialValues,
		actions: any
	) => {
		try {
			await create(values);
			handleClose();
		} catch (err: any) {
			console.log('ERR', err);
			if (!err?.status) {
				toast.error('No Server Response');
			} else if (err?.status === 401) {
				toast.error(err.data.message);
			} else if (err?.status === 403) {
				toast.error(err.data.message);
			} else {
				toast.error('Create User Failed!');
			}
		}
	};

	useEffect(() => {
        if(isSuccess) {
            toast.success("User created successfully")
        }
        if(isError && error !== undefined) {
            //@ts-ignore
            toast.error(error.data.error)
        }

    }, [error, isError, isSuccess])

	const content = isLoading ? (
		<Spinner size="lg" />
	) : (
		<section className="container">
			<div className="row">
				<div className="col-lg-12">
					<div className='general-side-p'>
						<div>
							<h4 className='title-modal'>Create Admin Users</h4>
						</div>
						<div className="container-form">
							<Formik
								initialValues={UtilsForm.IntialValuesFromModel(model)}
								validationSchema={validation}
								onSubmit={handleSubmit}
							>
								{({ isSubmitting, dirty, isValid, errors, touched, values, setFieldValue }) => (
									<Form id={formId}>
										<Holder
											formField={formField}
											values={values}
											errors={errors}
											touched={touched}
											setFieldValue={setFieldValue}
										/>

										<button
											type="submit"
											disabled={!(isValid && dirty)}
											className={`${
												!(isValid && dirty) && 'btn-outline-light'
											} btn btn-primary btn-block mb-1 full-width`}
										>
											Create
										</button>
									</Form>
								)}
							</Formik>
						</div>
					</div>
				</div>
			</div>
		</section>
	);

	return content;
};

export default CreateSubAdmin