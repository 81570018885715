import React from 'react';
import { Field, FormikErrors, FormikTouched } from 'formik';
import { GlobalTypes } from 'src/utils/types';

interface HolderProps {
	errors: FormikErrors<{ [field: string]: any }>;
	values: object;
	touched: FormikTouched<{ [field: string]: any }>;
	formField: GlobalTypes.FormFieldModel;
    setFieldValue: (field: string, value: any, shouldValidate?: boolean | undefined) => void;
}

const Holder: React.FC<HolderProps> = (props) => {
	const { errors, touched, setFieldValue } = props;
	const {
		formField: { username, password, confirmPassword, role },
	} = props;

	let usernameErr: string = errors.username as string;
	let passwordErr: string = errors.password as string;
	let confirmPasswordErr: string = errors.confirmPassword as string;
	let roleErr: string = errors.role as string;

	return (
		<>
			<div className="create-admin-field mb-4">
                <label className='create-admin-label' htmlFor={username.name}>
                    {username.label}
				</label>
				<Field type="text" name={username.name} placeholder={`${errors.username && touched.username ? usernameErr : ""}`} className={`form-control-field ${errors.username && touched.username && "error-msg-field"}`} />
			</div>

			<div className="create-admin-field mb-4">
                <label className='create-admin-label' htmlFor={password.name}>
                    {password.label}
				</label>
				<Field type="password" name={password.name} placeholder={`${errors.password && touched.password ? passwordErr : ""}`} className={`form-control-field ${errors.password && touched.password && "error-msg-field"}`} />
			</div>
            <div className="create-admin-field mb-4">
                <label className='create-admin-label' htmlFor={confirmPassword.name}>
                    {confirmPassword.label}
				</label>
				<Field type="password" name={confirmPassword.name} placeholder={`${errors.confirmPassword && touched.confirmPassword ? confirmPasswordErr : ""}`} className={`form-control-field ${errors.confirmPassword && touched.confirmPassword && "error-msg-field"}`} />
			</div>
            <div className="create-admin-field mb-4">
                <label className='create-admin-label' htmlFor={role.name}>
                    {role.label}
				</label>
				<Field name={role.name} as="select" onChange={(e: any) => setFieldValue(role.name, e.target.value, true)} className={`form-control-field ${errors.role && touched.role && "error-msg-field"}`}>
                    <option value="DEFAULT" hidden>---Select Role---</option>
                    <option value="admin">Admin</option>
                    <option value="operator">Operator</option>
                </Field>
			</div>
		</>
	);
};

export default Holder;