import React, {useState, useEffect, useRef} from 'react';
import { GlobalTypes } from 'src/utils/types';
import randomcolor from "randomcolor"
import { useAppDispatch } from 'src/app/store';
import {setDetailUser} from "src/features/admin/usersSlice"


interface UserListItemProps {
	user: GlobalTypes.BackofficeUsers;
    setOpenStatusModal: React.Dispatch<React.SetStateAction<boolean>>
	setOpenResetPassword: React.Dispatch<React.SetStateAction<boolean>>
	setOpenChangeRole: React.Dispatch<React.SetStateAction<boolean>>
}

const BackofficeUsersListItem: React.FC<UserListItemProps> = ({ user, setOpenStatusModal, setOpenResetPassword, setOpenChangeRole }) => {
	let bgColor = randomcolor({luminosity:"light", format: 'rgba', alpha: 0.6});
	let letterColor = randomcolor({luminosity:"dark"})
	let content;
	let createdAt = new Date(user.createdAt)

	const ref= useRef<HTMLDivElement>(null)

	const [openDropdown, setOpenDropdown] = useState(false)

	const dispatch = useAppDispatch();

    const onEditClick = (user: GlobalTypes.BackofficeUsers) => {
        dispatch(setDetailUser(user))
        setOpenDropdown(prev => !prev)
    }

	useEffect(() => {
		const handleClickOutside = (event: MouseEvent) => {
		  if(ref.current && openDropdown && !ref.current.contains(event.target as Node)){
			setOpenDropdown(false)
			}
		};
		document.addEventListener("mousedown", handleClickOutside);
  
		return () => {
		  document.removeEventListener("mousedown", handleClickOutside);
			 };
	  }, [ref, openDropdown]);


    
	content = (
		<tr className="list-block">
			<td className="list-block-container">
				{' '}
				<div className='user-image-container' style={{backgroundColor: bgColor, color: letterColor}}>{user.username.slice(0,1)}</div>{user.username}
			</td>
			<td className="list-block-size">{createdAt.toLocaleDateString('en-CA', {timeZone: 'UTC'})}</td>
			<td className="list-block-size created-by-username">{user.createdByUsername}</td>
			<td className="list-block-size"><span className={`admin-status ${user.status}`}>{user.status}</span></td>
			<td className="list-block-size"><span className={`admin-role`}>{user.role && user.role}</span></td>
			<td className='position-relative'>
				<div ref={ref} className={`status detail ${openDropdown ? 'open' : ''}`} onClick={() => onEditClick(user)}>
					<div>Edit</div>
				</div>
				<div className={`edit-options-dropdown ${openDropdown ? 'open' : 'close'}`}>
					<ul className='edit-options-list'>
						<li className='edit-options-list-item' onClick={() => setOpenStatusModal(true)}>
							Status
						</li>
						<li className='edit-options-list-item' onClick={() => setOpenChangeRole(true)}>
							Role
						</li>
						<li className='edit-options-list-item' onClick={() => setOpenResetPassword(true)}>
							Password
						</li>
						<li className='edit-options-list-item'>
							Delete
						</li>
					</ul>
				</div>
			</td>
		</tr>
	);

	return content;
};

export default BackofficeUsersListItem;