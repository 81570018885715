import * as yup from 'yup';

//model
import model from './model';

const {
	formField: { password, confirmPassword },
} = model;

const validation = yup.object().shape({
	[password.name]: yup.string().min(8, 'Your password is too short.').required(`${password.requireErrorMsg}`),
    [confirmPassword.name]: yup
    .string()
    .required(`${confirmPassword.requireErrorMsg}`)
    .oneOf([yup.ref('password')], 'Your passwords do not match.'),
});

export default validation;