import React, { useEffect, useRef } from "react";
import ReactPortal from "src/components/common/Portal";
import { MdOutlineClose } from 'react-icons/md';

interface ModalProps {
    children: JSX.Element;
    isOpen: boolean;
    handleClose: () => void;
}

const Modal: React.FC<ModalProps> = ({ children, isOpen, handleClose}) => {

    const ref = useRef<HTMLDivElement>(null)

    useEffect(() => {
        const handleClickOutside = (event: MouseEvent) => {
          if(ref.current && isOpen && !ref.current.contains(event.target as Node)){
            handleClose()
            }
        };
        document.addEventListener("mousedown", handleClickOutside);
  
        return () => {
          document.removeEventListener("mousedown", handleClickOutside);
        };
      }, [ref, isOpen, handleClose]);

    if (!isOpen) return null;

    return (
        <ReactPortal wrapperId="user-single-detail-modal">
            <div className="modal-layer">
                <div onClick={handleClose} className="close-button">
                    <MdOutlineClose />
                </div>
                <div ref={ref} className="modal-real-content">
                    {children}
                </div>
            </div>
        </ReactPortal>
    );
};

export default Modal;