import React from 'react';
import { Options } from 'src/utils/filterSelectValues';

interface RoleProps {
	onChange(e: React.ChangeEvent<HTMLSelectElement>): void;
	currentValue: string;
}

const ChangeAdminRole: React.FC<RoleProps> = ({ onChange, currentValue }) => {
	return (
		<>
			<select 
				className={`select-styling  r-m-2`}
				onChange={(e) => {
					onChange(e);
				}}
				defaultValue={currentValue}
			>
				<option value="" disabled hidden>
					Role
				</option>
				{Options.adminRoleValues.map((e, i) => {
					return (
						<option className='select-options' key={i} value={e.value}>
							{e.label}
						</option>
					);
				})}
			</select>
		</>
	);
};

export default ChangeAdminRole;