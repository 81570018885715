import React from 'react';
import { Field, FormikErrors, FormikTouched } from 'formik';
import { GlobalTypes } from 'src/utils/types';

interface HolderProps {
	errors: FormikErrors<{ [field: string]: any }>;
	values: object;
	touched: FormikTouched<{ [field: string]: any }>;
	formField: GlobalTypes.FormFieldModel;
}

const Holder: React.FC<HolderProps> = (props) => {
	const { errors, touched, values } = props;
	const {
		formField: { password, confirmPassword },
	} = props;

	let passwordErr: string = errors.password as string;
	let confirmPasswordErr: string = errors.confirmPassword as string;

	return (
		<>
			<div className="create-admin-field mb-4">
                <label className='create-admin-label' htmlFor={password.name}>
                    {password.label}
				</label>
				<Field type="password" name={password.name} placeholder={`${errors.password && touched.password ? passwordErr : ""}`} className={`form-control-field ${errors.password && touched.password && "error-msg-field"}`} />
				<div className="error-msg-absolute">
					<span className={`${errors.password && touched.password && "error-msg-field"}`}>{errors.password && touched.password && passwordErr}</span>
				</div>
			</div>

			<div className="create-admin-field mb-4">
                <label className='create-admin-label' htmlFor={confirmPassword.name}>
                    {confirmPassword.label}
				</label>
				<Field type="password" name={confirmPassword.name} placeholder={`${errors.confirmPassword && touched.confirmPassword ? confirmPasswordErr : ""}`} className={`form-control-field ${errors.confirmPassword && touched.confirmPassword && "error-msg-field"}`} />
			</div>
		</>
	);
};

export default Holder;