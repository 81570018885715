import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { RootState } from 'src/app/store';

const baseQuery = fetchBaseQuery({
	baseUrl: process.env.REACT_APP_SERVER_URL,
	// credentials: 'include',
	prepareHeaders: (headers, { getState, endpoint }) => {
		const { token } = (getState() as RootState).auth;

		if (token) {
			headers.set('authorization', `Bearer ${token}`);
			headers.set('Accept', 'application/json');
		}
		return headers;
	},
});


export const apiSlice = createApi({
	baseQuery: baseQuery,
	tagTypes: ['VpnAccounts', 'Users','VpnAccountsPlans', 'COUNT'],
	endpoints: (builder) => ({}),
});
