import { useState, useEffect } from 'react';
import { DateRangePicker } from 'react-date-range';
import { BsFillCalendar2WeekFill } from 'react-icons/bs';
import { IoMdSend } from 'react-icons/io';
import { formatDate } from "src/utils/formatDate"

import 'react-date-range/dist/styles.css';
import 'react-date-range/dist/theme/default.css';

interface DateRangeProps {
	onDateRangeFilter: (fromDate: string, toDate: string) => void
}

const FilterDateRange: React.FC<DateRangeProps> = ({onDateRangeFilter}) => {
	const [isOpen, setIsOpen] = useState(false);
	const [selectedDates, setSelectedDates] = useState([{
		startDate: new Date(),
		endDate: new Date(),
		key: 'selection',
	}])

	const handleSetDatesForFilter = () => {
		let fromDate = formatDate(selectedDates[0].startDate)
		let toDate = formatDate(selectedDates[0].endDate)
		onDateRangeFilter(fromDate, toDate)
		setIsOpen(false);
	}


	return (
		<div className="position-relative">
			<div className="d-flex align-items-center">
				<button
					onClick={() => setIsOpen((prev) => !prev)}
					className={isOpen ? 'date-range-btn open' : 'date-range-btn'}
				>
					{/* <BsFillCalendar2WeekFill
						className="spacing-from-icon"
					/> */}
					<div style={{width: 50, textAlign: 'left'}}>...</div>{' '}
				</button>
				<div className='datepicker-divider'></div>
				<button
					style={{ display: selectedDates[0]?.startDate !== new Date() && selectedDates[0]?.endDate !== new Date() ? 'flex' : 'none' }}
					onClick={() => handleSetDatesForFilter()}
					className="reset-date-range"
				>
					<IoMdSend />
				</button>
			</div>
			<div
				style={{ display: isOpen ? 'block' : 'none' }}
				className="date-range-dropdown"
			>
				<DateRangePicker
					onChange={(item: any) => setSelectedDates([item.selection])}
					//@ts-ignore
					showSelectionPreview={false}
					moveRangeOnFirstSelection={false}
					definedRange={false}
					months={1}
					staticRanges={[]}
					inputRanges={[]}
					ranges={selectedDates}
					direction="horizontal"
				/>
			</div>
		</div>
	);
};

export default FilterDateRange;