import React, {useState} from 'react';
import { Options } from 'src/utils/filterSelectValues';

interface StatusProps {
	onChange(e: React.ChangeEvent<HTMLSelectElement>): void;
	currentValue: string;
}

const ChangeAdminStatus: React.FC<StatusProps> = ({ onChange, currentValue }) => {
	const [selectedCurrentValue, setSelectedCurrentValue] = useState(currentValue)
	console.log("CURRENT VALUE", currentValue)
	return (
		<>
			<select 
				className={`select-styling ${selectedCurrentValue} r-m-2`}
				onChange={(e) => {
					onChange(e);
					setSelectedCurrentValue(e.target.value)
				}}
				defaultValue={currentValue}
			>
				<option value="" disabled hidden>
					Status
				</option>
				{Options.adminStatusValues.map((e, i) => {
					return (
						<option key={i} value={e.value}>
							{e.label}
						</option>
					);
				})}
			</select>
		</>
	);
};

export default ChangeAdminStatus;