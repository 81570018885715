import {useState, useEffect} from "react"

import { Link, useLocation } from 'react-router-dom';
import logo from "src/textures/logo.png"
import {NavbarLinks} from "src/utils/navLinks"
import NavbarItem from './navbarItem';

const Navbar = () => {
	const location = useLocation()
	const [dropdownList, setDropdownList] = useState(
		NavbarLinks.navLinks
	);

	useEffect(() => {
		let currentDropdown: string = '';
		for (let dropdown in dropdownList) {
			for (let link of dropdownList[dropdown].subLink) {
	
				if (dropdownList[dropdown].link && link.path && (link.path.includes(location.pathname) || location.pathname.includes(link.path))) {
					currentDropdown = dropdown;
				}
			}
		}

		if (dropdownList[currentDropdown as unknown as number] && !dropdownList[currentDropdown as unknown as number].open) handleOpen(currentDropdown)();
	}, [location.pathname, dropdownList]);

	const dropdownOpen = () => {
		for (let dropdown in dropdownList) {
			if (dropdownList[dropdown].open){
				return dropdown
			}
		}
	};

	const handleOpen = (dropdown: string) => {
		// returns a function
		return () => {
			let currentlyOpen = dropdownOpen();
			currentlyOpen = currentlyOpen ? currentlyOpen : undefined;
			setDropdownList((prevState) => {
				let newState = { ...prevState };
				newState[dropdown as unknown as number] = { ...prevState[dropdown as unknown as number], open: true };
				if (currentlyOpen) {
					newState[currentlyOpen as unknown as number] = {
						...prevState[currentlyOpen as unknown as number],
						open: false,
					};
				}
				return newState;
			});
		};
	};
	return (
		<nav className="side-navbar">
			<Link to="/dashboard" className="side-navbar-brand">
				<img src={logo} alt="my moon vpn logo" />
			</Link>

			<div className="d-felx align-items-center justify-content-between">
				<ul className="nav-link-list">
					{
						Object.keys(dropdownList).map((navLink, index) => {
							return <NavbarItem key={dropdownList[navLink as unknown as number].title + index} img={dropdownList[navLink as unknown as number].img} toggleOpen={handleOpen(navLink)} isOpen={dropdownList[navLink as unknown as number].open} dropdown={dropdownList[navLink as unknown as number].dropdown} title={dropdownList[navLink as unknown as number].title} link={dropdownList[navLink as unknown as number].link} subLink={dropdownList[navLink as unknown as number].subLink}  />
						})
					}
				</ul>
			</div>
		</nav>
	);
};

export default Navbar;
