import * as yup from 'yup';

//model
import model from './model';

const {
	formField: { username, password },
} = model;

const validation = yup.object().shape({
	[username.name]: yup
		.string()
		.min(1)
		.required(`${username.requireErrorMsg}`),
	[password.name]: yup.string().min(1).required(`${password.requireErrorMsg}`),
});

export default validation;
