import React from 'react';
import Select from 'react-select';
import { Options } from 'src/utils/filterSelectValues';

interface StatusProps {
	onChange: (value: string) => void;
}

const SelectAdminRole: React.FC<StatusProps> = ({ onChange }) => {
	return (
		<>
			<Select
			isMulti={false}
			defaultValue={Options.adminFilterRoleValues[0]}
			options={Options.adminFilterRoleValues}
			placeholder="Role..."
			onChange={(e) => {
				onChange(e!['value' as keyof typeof e])
			}}
			/>
		</>
	);
};

export default SelectAdminRole;