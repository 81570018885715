import { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';

import { useGetUsersQuery } from "src/features/admin/usersApiSlice"
import { selectAllUsers, setAllUsers} from 'src/features/admin/usersSlice';
import { useAppSelector, useAppDispatch } from 'src/app/store';
import { GlobalTypes } from 'src/utils/types';

import ListHeader from 'src/components/common/BackofficeUsersListItem/ListHeader';
import BackofficeUsersListItem from 'src/components/common/BackofficeUsersListItem';
import Modal from 'src/components/shared/modal';
import ChangeStatusBackofficeUser from 'src/components/common/ChangeStatusBackofficeUser';
import CreateSubAdmin from 'src/components/common/CreateSubAdmin';
import ResetPassword from 'src/components/common/ResetPassword';
import SelectAdminRole from 'src/components/shared/filters/selectAdminRole';
import ChangeBackofficeUserRole from 'src/components/common/ChangeBackofficeUserRole';
import Spinner from 'src/components/shared/spinner';


const BackofficeUsersList: React.FC = () => {
    const [statusModalOpen, setStatusModalOpen] = useState(false)
	const [createUserModal, setCreateUserModal] = useState(false)
	const [createResetPasswordModal, setResetPasswordModal] = useState(false)
	const [createChangeRoleModal, setChangeRoleModal] = useState(false)
	const [roleSearch, setRoleSearch] = useState('');

	const dispatch = useAppDispatch();
	const backofficeUsers: Array<GlobalTypes.BackofficeUsers> = useAppSelector(selectAllUsers);

	const {
		data: users,
		isLoading,
		isSuccess,
		isError,
		error,
	} = useGetUsersQuery({ role: roleSearch });

	useEffect(() => {
		if (users) {
			dispatch(setAllUsers(users));
		}
	}, [users, dispatch]);

	const onStatusChange = (value: string) => {
		setRoleSearch(value);
	};
    
    const handleChangeStatusClose = () => {
        setStatusModalOpen(false)
    }

	const handleCreateUserClose = () => {
		setCreateUserModal(false)
	}

	const handleResetPasswordClose = () => {
		setResetPasswordModal(false)
	}

	const handleChangeRoleClose = () => {
		setChangeRoleModal(false)
	}

	return (
		<section className="session-bg">
			<div className='general-side-p'>
				<div className='mb-5'>
					<button className='create-user-btn' onClick={() => setCreateUserModal(true)}>Create Admin</button>
				</div>
                <div className='d-flex mb-4'>
                    <SelectAdminRole onChange={onStatusChange} />
                </div>
				{!isLoading && isSuccess ? 
					<table>
						<ListHeader />
						<tbody>
							{backofficeUsers ? backofficeUsers.map((user: GlobalTypes.BackofficeUsers, i: number) => {
								return <BackofficeUsersListItem key={user.id} user={user} setOpenStatusModal={setStatusModalOpen} setOpenResetPassword={setResetPasswordModal} setOpenChangeRole={setChangeRoleModal} />;
							}): <tr><td>There are no Users at the moment, <Link to="/users/create">Create a new one</Link></td></tr>}
						</tbody>
					</table> : 
					<Spinner size="lg" />
				}
			</div>
			<Modal handleClose={handleCreateUserClose} isOpen={createUserModal}>
                <CreateSubAdmin handleClose={handleCreateUserClose} />
            </Modal>
            <Modal handleClose={handleChangeStatusClose} isOpen={statusModalOpen}>
                <ChangeStatusBackofficeUser handleClose={handleChangeStatusClose} />
            </Modal>
			<Modal handleClose={handleResetPasswordClose} isOpen={createResetPasswordModal}>
                <ResetPassword handleClose={handleResetPasswordClose} id="" />
            </Modal>
			<Modal handleClose={handleChangeRoleClose} isOpen={createChangeRoleModal}>
                <ChangeBackofficeUserRole handleClose={handleChangeRoleClose} />
            </Modal>
		</section>
				
			
	)
};

export default BackofficeUsersList;