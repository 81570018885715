import React, {useState, useEffect} from "react"
import { selectDetailUser } from 'src/features/admin/usersSlice';
import { useAppSelector } from 'src/app/store';
import { GlobalTypes } from 'src/utils/types';
import ChangeAdminStatus from "src/components/form/changeUserStatus";
import Spinner from "src/components/shared/spinner";

import { useStatusMutation } from 'src/features/admin/usersApiSlice';
import toast from "react-hot-toast";


interface CSProps {
    handleClose: () => void;
}

const ChangeStatusBackofficeUser: React.FC<CSProps> = ({handleClose}) => {

    const [selectedStatus, setSelectedStatus] = useState('');

    const [status, {isLoading, isSuccess, isError, error}] = useStatusMutation();

    const backofficeUser: GlobalTypes.BackofficeUsers = useAppSelector(selectDetailUser);

    const onStatusChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
		e.preventDefault();
		setSelectedStatus(e.target.value);
	};

    const onEditStatus = async () => {
        try {
            await status({id:backofficeUser.id, status:selectedStatus})
            handleClose()
        } catch (err: any) {
			console.log('ERR', err);
			if (!err?.status) {
				toast.error('No Server Response');
			} else if (err?.status === 401) {
				toast.error("Unauthorized action");
			} else if (err?.status === 403) {
				toast.error(err.data.message);
			} else {
				toast.error('Create User Failed!');
			}
		}
    }

    useEffect(() => {
        if(isSuccess) {
            toast.success("Status changed successfully")
        }
        if(isError && error !== undefined) {
            //@ts-ignore
            toast.error(error.data.error)
        }

    }, [error, isError, isSuccess])

    let content = null;

    if(backofficeUser) {
       content = (
        <div className="full-body">
            <div className="container-full-width">
                <div>
                    <h4 className='title-modal'>Edit Sub Admin</h4>
                </div>
                <div className="change-sub-admin-status">
                    <span>Change Status</span>
                    <ChangeAdminStatus currentValue={backofficeUser.status} onChange={onStatusChange} />
                    {<button disabled={selectedStatus !== backofficeUser.status && selectedStatus !== '' ? false : true} onClick={() => onEditStatus()} className={`edit-status-button ${selectedStatus !== backofficeUser.status && selectedStatus !== '' ? "" : "disabled"}`}>{isLoading ? 'Processing...' : 'Save'}</button>}
                </div>
            </div>
        </div>
       )
    }

    if(!backofficeUser) {
        return <Spinner size="lg" />
    }

    return content
}

export default ChangeStatusBackofficeUser