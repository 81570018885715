import React, {useEffect, useState} from "react"
import { selectDetailUser } from 'src/features/admin/usersSlice';
import { useAppSelector } from 'src/app/store';
import { GlobalTypes } from 'src/utils/types';
import ChangeAdminRole from "src/components/form/changeUserRole";
import Spinner from "src/components/shared/spinner";

import { useRoleMutation } from 'src/features/admin/usersApiSlice';
import toast from "react-hot-toast";


interface CSProps {
    handleClose: () => void;
}

const ChangeBackofficeUserRole: React.FC<CSProps> = ({handleClose}) => {

    const [selectedRole, setSelectedRole] = useState('');

    const [role, {isLoading, isSuccess, isError, error}] = useRoleMutation();

    const backofficeUser: GlobalTypes.BackofficeUsers = useAppSelector(selectDetailUser);

    const onRoleChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
		e.preventDefault();
		setSelectedRole(e.target.value);
	};

    const onEditRole = async () => {
        try {
            await role({id:backofficeUser.id, role:selectedRole})
            handleClose()
        } catch (err: any) {
			console.log('ERR', err);
			if (!err?.status) {
				toast.error('No Server Response');
			} else if (err?.status === 401) {
				toast.error("Unauthorized action");
			} else if (err?.status === 403) {
				toast.error(err.data.message);
			} else {
				toast.error('Create User Failed!');
			}
		}
    }

    useEffect(() => {
        if(isSuccess) {
            toast.success("Role changed successfully")
        }
        if(isError && error !== undefined) {
            //@ts-ignore
            toast.error(error.data.error)
        }

    }, [error, isError, isSuccess])

    console.log("ERROR", error)
    console.log("IS ERROR", isError)

    let content = null;

    if(backofficeUser) {
       content = (
        <div className="full-body">
            <div className="container-full-width">
                <div>
                    <h4 className='title-modal'>Edit Sub Admin</h4>
                </div>
                <div className="change-sub-admin-status">
                    <span>Change Role</span>
                    <ChangeAdminRole currentValue={backofficeUser.role} onChange={onRoleChange} />
                    {<button disabled={selectedRole !== backofficeUser.role && selectedRole !== '' ? false : true} onClick={() => onEditRole()} className={`edit-status-button ${selectedRole !== backofficeUser.role && selectedRole !== '' ? "" : "disabled"}`}>{isLoading ? 'Processing...' : 'Save'}</button>}
                </div>
            </div>
        </div>
       )
    }

    if(!backofficeUser) {
        return <Spinner size="lg" />
    }

    return content
}

export default ChangeBackofficeUserRole