import React, {useState, useEffect} from "react"

import { useGetAllPlansQuery } from 'src/features/vpnAccounts/vpnAccountsApiSlice';
import { setDetailAccountUserPlan, selectDetailAccountUser, } from 'src/features/vpnAccounts/vpnAccountsSlice';
import { useAppDispatch, useAppSelector } from 'src/app/store';
import { GlobalTypes } from 'src/utils/types';
import Spinner from 'src/components/shared/spinner';
import { BsApple } from 'react-icons/bs';
import { FcGoogle } from 'react-icons/fc';
import { FaIdCard } from 'react-icons/fa';
import { RiBarcodeLine } from 'react-icons/ri';
import {BiEdit} from "react-icons/bi"
import Modal from "src/components/shared/modal";
import ChangeStatusAccount from "src/components/common/ChangeStatusAccount";

const UserDetail: React.FC = () => {
	const [statusModalOpen, setStatusModalOpen] = useState(false)

	const dispatch = useAppDispatch();
	const currentUser: GlobalTypes.SingleAccountUser = useAppSelector(selectDetailAccountUser);

	const {
		data: plans,
		isLoading,
		isSuccess: isSuccess1,
	} = useGetAllPlansQuery({accountId: currentUser.accountId});

	useEffect(() => {
		console.log("running")
		if (plans) {
			dispatch(setDetailAccountUserPlan(plans));
		}
	}, [plans, dispatch]);

	const handleChangeStatusClose = () => {
        setStatusModalOpen(false)
    }

	console.log("CURRENT USER", currentUser)


	if (!currentUser) {
		return (
			<Spinner size="lg" />
		);
	} else if (currentUser) {
		return (
			<section className="session-bg">
				<div className='general-side-p'>
					<div className="mb-4"><span className="blue-text">Email</span> <span className="current-plan">{currentUser.email || currentUser.passCode}</span></div>
					<div className='flex-align mb-4'>
						<span className="blue-text margin-r-2">
							Status
						</span> 
						<span className={`status ${currentUser.AccountStatus}`}> 
							{currentUser.AccountStatus} 
						</span>
						<button className="account-status-edit" onClick={() => setStatusModalOpen(prev => !prev)}>
							<BiEdit />
						</button>
					</div>
					<div className='flex-align mb-4'>
						<div className='flex-align half-full-width'>Auth Type {currentUser.authTypes['google'] && <FcGoogle className='auth-type-icons' />} {currentUser.authTypes['anonymous'] && <RiBarcodeLine className='auth-type-icons' />} {currentUser.authTypes['google'] && <BsApple className='auth-type-icons' />} {currentUser.authTypes['password'] && <FaIdCard className='auth-type-icons' />}</div>
						{<div className='flex-align'><span className="blue-text">Current Plan</span> <span className="current-plan"> {isLoading ? <Spinner size="sm" /> : currentUser.plan?.plan || "No Plan"}</span></div>}
					</div>
				</div>
				<Modal handleClose={handleChangeStatusClose} isOpen={statusModalOpen}>
                	<ChangeStatusAccount id={currentUser.accountId} currentStatus={currentUser.AccountStatus} handleClose={handleChangeStatusClose} />
            	</Modal>
			</section>
		);
	} else {
		return null;
	}
};

export default UserDetail;
