const model = {
	formId: 'resetPassword',
	formField: {
		newPassword: {
			name: 'newPassword',
			label: 'New Password',
			requireErrorMsg: 'New Password is required',
			value: '',
		},
		confirmNewPassword: {
			name: 'confirmNewPassword',
			label: 'Confirm New Password',
			requireErrorMsg: 'Confirm New Password is required',
			value: '',
		},
        currentPassword: {
			name: 'currentPassword',
			label: 'Current Password',
			requireErrorMsg: 'Current Password is required',
			value: '',
		},
	},
};

export default model;