import React from 'react';
import { Field, FormikErrors, FormikTouched } from 'formik';
import { GlobalTypes } from 'src/utils/types';

interface HolderProps {
	errors: FormikErrors<{ [field: string]: any }>;
	values: object;
	touched: FormikTouched<{ [field: string]: any }>;
	formField: GlobalTypes.FormFieldModel;
}

const Holder: React.FC<HolderProps> = (props) => {
	const { errors, touched, values } = props;
	const {
		formField: { newPassword, confirmNewPassword, currentPassword },
	} = props;

	let newPasswordErr: string = errors.newPassword as string;
	let confirmNewPasswordErr: string = errors.confirmNewPassword as string;
    let currentPasswordErr: string = errors.currentPassword as string;

	return (
		<>
			<div className="create-admin-field mb-4">
                <label className='create-admin-label' htmlFor={newPassword.name}>
                    {newPassword.label}
				</label>
				<Field type="password" name={newPassword.name} placeholder={`${errors.newPassword && touched.newPassword ? newPasswordErr : ""}`} className={`form-control-field ${errors.newPassword && touched.newPassword && "error-msg-field"}`} />
				<div className="error-msg-absolute">
					<span className={`${errors.newPassword && touched.newPassword && "error-msg-field"}`}>{errors.newPassword && touched.newPassword && newPasswordErr}</span>
				</div>
			</div>

			<div className="create-admin-field mb-4">
                <label className='create-admin-label' htmlFor={confirmNewPassword.name}>
                    {confirmNewPassword.label}
				</label>
				<Field type="password" name={confirmNewPassword.name} placeholder={`${errors.confirmNewPassword && touched.confirmNewPassword ? confirmNewPasswordErr : ""}`} className={`form-control-field ${errors.confirmNewPassword && touched.confirmNewPassword && "error-msg-field"}`} />
			</div>

			<div className="create-admin-field mb-4">
                <label className='create-admin-label' htmlFor={currentPassword.name}>
                    {currentPassword.label}
				</label>
				<Field type="password" name={currentPassword.name} placeholder={`${errors.currentPassword && touched.currentPassword ? currentPasswordErr : ""}`} className={`form-control-field ${errors.currentPassword && touched.currentPassword && "error-msg-field"}`} />
			</div>
		</>
	);
};

export default Holder;