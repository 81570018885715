import React, {useEffect} from "react"
import { Form, Formik } from 'formik';
import UtilsForm from 'src/utils/form';
import toast from 'react-hot-toast';

import { useResetMutation } from "src/features/admin/usersApiSlice"
import { useAppSelector } from 'src/app/store';
import { selectDetailUser } from 'src/features/admin/usersSlice';

import model from 'src/components/form/resetPassword/model';
import Holder from 'src/components/form/resetPassword/holder';
import validation from 'src/components/form/resetPassword/validation';
import { GlobalTypes } from 'src/utils/types';
import Spinner from "src/components/shared/spinner";

interface RPProps {
    handleClose: () => void;
	id: string | undefined
}

const ResetPassword: React.FC<RPProps> = ( {handleClose, id}) => {
	const { formId, formField }: GlobalTypes.Model = model;
	
	const [reset, { isLoading, isSuccess, isError, error }] = useResetMutation();

	const backofficeUser: GlobalTypes.BackofficeUsers = useAppSelector(selectDetailUser);

	const handleSubmit = async (
		values: GlobalTypes.ResetPasswordInitialValues,
		actions: any
	) => {

		try {
			await reset({id: id !== '' ? id : backofficeUser.id, body:values })
            handleClose()
		} catch (err: any) {
			console.log('ERR', err);
			if (!err?.status) {
				toast.error('No Server Response');
			} else if (err?.status === 401) {
				toast.error("Unauthorized action");
			} else if (err?.status === 403) {
				toast.error(err.data.message);
			} else {
				toast.error('Action Failed');
			}
		}
	};

	useEffect(() => {
        if(isSuccess) {
            toast.success("Password Reseted successfully")
        }
        if(isError && error !== undefined) {
            console.log("ERROR", error)
            //@ts-ignore
            toast.error(error.data.error)
        }

    }, [error, isError, isSuccess])

	const content = isLoading ? (
		<Spinner size="lg"/>
	) : (
		<section className="container">
			<div className="row">
				<div className="col-lg-12">
                    <div className='general-side-p'>
                        <div>
							<h4 className='title-modal'>Reset password</h4>
						</div>
                        <div className="container-form">
                            <Formik
                                initialValues={UtilsForm.IntialValuesFromModel(model)}
                                validationSchema={validation}
                                onSubmit={handleSubmit}
                            >
                                {({ isSubmitting, dirty, isValid, errors, touched, values }) => (
                                    <Form id={formId}>
                                        <Holder
                                            formField={formField}
                                            values={values}
                                            errors={errors}
                                            touched={touched}
                                        />

                                        <button
                                            type="submit"
                                            disabled={!(isValid && dirty)}
                                            className={`${
                                                !(isValid && dirty) && 'btn-outline-light'
                                            } btn btn-primary btn-block mb-1 full-width`}
                                        >
                                            Save
                                        </button>
                                    </Form>
                                )}
                            </Formik>
                        </div>
                    </div>
				</div>
			</div>
		</section>
	);

	return content;
};

export default ResetPassword;