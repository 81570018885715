import React from 'react';
import { Link, useNavigate, useLocation, useParams } from 'react-router-dom';
import { RiArrowDownSLine } from 'react-icons/ri';
import ActiveNav from "src/components/shared/navbar/activeLink"
import {selectCurrentId} from "src/features/auth/authSlice"
import {useAppSelector} from "src/app/store"


interface NavbarItemListProps {
    img: string;
    dropdown: boolean;
    isOpen: boolean;
    title: string;
    link: string;
    subLink: {text: string; path: string}[]
    toggleOpen: () => void
}


const NavbarItem: React.FC<NavbarItemListProps> = ({img, dropdown, title, link, subLink, isOpen, toggleOpen}) => {
    const navigate = useNavigate();
    const location = useLocation()
    const params = useParams()

    const id = useAppSelector(selectCurrentId);


    if( !dropdown ) {
        return   <li onClick={() => {
                        toggleOpen()
                        navigate(link)
                    }} 
                    className={`nav-item ${location.pathname === link ? 'active' : ''}`}
                    >
                        <Link to={link || "asd"} >
                            <img className='nav-img' src={img} alt="dashboard" />
                            {title}
                        </Link>
                </li>       
	}
	return (
		<li className="nav-link">
			<div
				onClick={() => toggleOpen()}
				className={`nav-item ${isOpen ? 'active' : ''} `}
			>   
                <div>
                    <img className='nav-img' src={img} alt="dashboard" />
                    {title}
                </div>
				<RiArrowDownSLine className={`dropdown-arrow ${isOpen ? 'open' : 'close'}`} />
			</div>
            <ul className={`dropdown-links ${isOpen ? 'open' : 'close'}`}>
                <div className='inside-ul-nav-link-list'>
                    {subLink.map((el, index) => {
                        if( dropdown ){
                            return (
                                <li key={index}>
                                    <ActiveNav path={el.path}>
                                        <Link to={el.path} >
                                            {el.text}
                                        </Link>
                                    </ActiveNav>
                                </li>
                            );
                        } else {
                            return (
                                <li key={index}>
                                    <ActiveNav path={el.path}>
                                        <Link to={el.path} >
                                            {el.text}
                                        </Link>
                                    </ActiveNav>
                                </li>
                            );
                        }
                        
                    })}
                </div>
            </ul>
		</li>
	);
};

export default NavbarItem;