import * as yup from 'yup';

//model
import model from './model';

const {
	formField: { newPassword, confirmNewPassword, currentPassword },
} = model;

const validation = yup.object().shape({
	[newPassword.name]: yup.string().min(8, 'Your password is too short.').required(`${newPassword.requireErrorMsg}`),
    [confirmNewPassword.name]: yup
    .string()
    .required(`${confirmNewPassword.requireErrorMsg}`)
    .oneOf([yup.ref('newPassword')], 'Your passwords do not match.'),
    [currentPassword.name]: yup.string().min(8, 'Your password is too short.').required(`${currentPassword.requireErrorMsg}`),
});

export default validation;