import { useEffect, useState } from 'react';

import { useGetAllAccountsUsersQuery } from 'src/features/vpnAccounts/vpnAccountsApiSlice';
import { GlobalTypes } from 'src/utils/types';

import { useAppDispatch } from 'src/app/store';
import { selectAllAccountsUsers, selectUserListPage, setAllAccountsUsers, setUserListPage } from '../features/vpnAccounts/vpnAccountsSlice';
import { useAppSelector } from 'src/app/store';
import UsersListItem from 'src/components/common/UserListItem';
import ListHeader from 'src/components/common/UserListItem/ListHeader';
import Filters from 'src/components/shared/filters';
import Pagination from 'src/components/shared/pagination';
import Spinner from 'src/components/shared/spinner';
import toast from 'react-hot-toast';


const UsersList: React.FC = () => {
	const reduxUsers: Array<GlobalTypes.SingleAccountUser> = useAppSelector(selectAllAccountsUsers);
	const listPage:number = useAppSelector(selectUserListPage)
	const [page, setPage] = useState(listPage);
	const [sorting, setSorting] = useState({ key: 'email', ascending: false })
	
	const [sendParams, setSendParams] = useState({
		"statuses": '',
		"authTypes": '',
		"id": '',
		"accountId": '',
		"email": '',
		"fromDate": '',
		"toDate": '',
	});
	const dispatch = useAppDispatch();

	const {
		data: users,
		isLoading,
		isSuccess,
		isError,
		error,
		isUninitialized,
		isFetching
	} = useGetAllAccountsUsersQuery({ page: page, ...sendParams })
	
	useEffect(() => {
		if (users) {
			dispatch(setAllAccountsUsers(users));
		} 
		
		if (error !== undefined) {
			//@ts-ignore
			let errorString: string = error.data?.error as string
			toast.error(errorString)
		}
	}, [users, dispatch, error]);
	

	const onStatusChangeFilter = (array: Array<string>) => {
		setSendParams({
			...sendParams,
			statuses: array.toString(),
		});
	};

	const onAuthTypeChangeFilter = (array: Array<string>) => {
		setSendParams({
			...sendParams,
			authTypes: array.toString(),
		});
	};

	const onSearchFilter = (searchValues: {[key:string]: string}) => {
		setSendParams({
			...sendParams,
			...searchValues
		});
	};

	const onDateRangeFilter = (fromDate: string, toDate: string) => {
		setSendParams({
			...sendParams,
			fromDate: fromDate.toString(),
			toDate: toDate.toString()
		});
	};

	const onPageNext = () => {
		setPage(prev => prev + 1)
		dispatch(setUserListPage(page + 1))
	}

	const onPagePrev = () => {
		setPage(prev => prev - 1)
		dispatch(setUserListPage(page - 1))
	}

	const handleCloseFilters = () => {
        setSendParams({
            statuses: '',
            authTypes: '',
            id: '',
            accountId: '',
            email: '',
            fromDate: '',
            toDate: '',
        })
    }


	useEffect(() => {
        const currentUsersCopy = [...reduxUsers];
    
        const sortedCurrentUsers = currentUsersCopy.sort((a, b) => {
			//@ts-ignore
			return a[sorting.key as keyof typeof a].localeCompare(b[sorting.key as keyof typeof b]);
        });
    
        setAllAccountsUsers(
          sorting.ascending ? sortedCurrentUsers : sortedCurrentUsers.reverse()
        );
    }, [sorting]);

	function applySorting(key: string, ascending: boolean) {
		setSorting({ key: key, ascending: ascending });
	}

	return (
		<section className="session-bg">
			<div className='general-side-p'>
				<Filters onDateRangeFilter={onDateRangeFilter} handleCloseFilters={handleCloseFilters} onSearch={onSearchFilter} onStatusChange={onStatusChangeFilter} onAuthTypeChange={onAuthTypeChangeFilter} />
				{!isLoading && isSuccess && !isError && !isFetching ? 
					<table>
						<ListHeader setSort={applySorting} sorting={sorting} />
						<tbody>
							{reduxUsers && reduxUsers.map((user, i) => {
								return <UsersListItem key={i} user={user} />;
							})}
						</tbody>
					</table> : 
					<Spinner size="lg" />
				}
			</div>	
			<Pagination onPagePrev={onPagePrev} onPageNext={onPageNext} sendParams={sendParams} page={page} setPage={setPage} queryRequest='accountUsersCount' />
		</section>	
	)
};

export default UsersList;
