import React, {useState} from 'react';
import { BsPersonLinesFill } from 'react-icons/bs';
import { HiIdentification } from 'react-icons/hi';
import { MdEmail } from 'react-icons/md';
import { BiBarcodeReader } from 'react-icons/bi';
import { IoMdSend } from 'react-icons/io';




interface SearchProps {
	onSearch(searchValues: {[key:string]: string}): any;
}

const SearchInput: React.FC<SearchProps> = ({ onSearch }) => {

	const [searchChoiceOpen, setSearchChoiceOpen] = useState(false)
	const [searchSelectedValues, setSearchSelectedValues] = useState({key: "email", value: ""})

	const renderSelectSearchIcon = () => {
		let value = <MdEmail />

		if(searchSelectedValues.key === 'accountId') {
			value = <HiIdentification />
		}

		if(searchSelectedValues.key === 'passCode') {
			value = <BiBarcodeReader />
		}

		return value
	}

	const handleSetSearchValue = (e: React.MouseEvent<HTMLLIElement, MouseEvent>) => {
		setSearchSelectedValues({value: '', key:(e.target as HTMLInputElement).innerText})
		setSearchChoiceOpen(false)
	}

	const handleSendSearchAsParams = () => {
		
		const res = {
			[searchSelectedValues.key]: searchSelectedValues.value
		}

		onSearch(res)
	}

	const handleSendSearch = (e: React.KeyboardEvent<HTMLInputElement>) => {
		if(e.key === 'Enter') {
			handleSendSearchAsParams()
		}
	}

	return (
		<>	
			<div className='input-container'>
				<div className={`search-icon-container ${searchChoiceOpen ? 'open' : 'close'}`} onClick={() => setSearchChoiceOpen(prev => !prev)}>
					{renderSelectSearchIcon()}
				</div>
				<div className='search divider'></div>
				<input
					className={`input-styling ${searchChoiceOpen ? 'open' : 'close'} ${searchSelectedValues.value !== '' ? 'on' : ''}`}
					type="text"
					value={searchSelectedValues.value}
					placeholder="Search for transactions..."
					onChange={(e) => {
							setSearchSelectedValues({...searchSelectedValues, value:e.target.value})
						}
					}
					onKeyPress={(e) => handleSendSearch(e)}
				/>
				<div className={`search-options-dropdown ${searchChoiceOpen ? 'open' : 'close'}`}>
					<ul>
						<li onClick={(e) => handleSetSearchValue(e)}>
							email
						</li>
						<li onClick={(e) => handleSetSearchValue(e)}>
							passCode
						</li>
						<li onClick={(e) => handleSetSearchValue(e)}>
							accountId
						</li>
					</ul>
				</div>
				<button type='submit' className={`send-search ${searchSelectedValues.value !== '' ? 'on' : 'off'}`} onClick={() => handleSendSearchAsParams() }>
					<IoMdSend />
				</button>
			</div>
		</>
	);
};

export default SearchInput;
