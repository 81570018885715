function IntialValuesFromModel(Model: { formId: string; formField: object }) {
	let fields = Model.formField;
	let initialValues: any = {};
	Object.values(fields).map(
		(field: {
			name: string;
			label: string;
			requireErrorMsg: string;
			value: string;
		}) => {
			return (initialValues[field.name] = field.value);
		}
	);
	return initialValues;
}
let UtilsForm = {
	IntialValuesFromModel,
};

export default UtilsForm;
