import { Outlet } from 'react-router-dom';
import Navbar from 'src/components/shared/navbar';
import Adminbar from 'src/components/shared/adminbar';

const Layout = () => {
	return (
		<div className="general-bg">
			<Navbar />
			<div className="body-height">
				<Adminbar />
				<Outlet />
			</div>
		</div>
	);
};

export default Layout;
