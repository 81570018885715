import { apiSlice } from '../../app/api/apiSlice';
import { GlobalTypes } from 'src/utils/types';

export const usersApiSlice = apiSlice.injectEndpoints({
	endpoints: (builder) => ({
		getUsers: builder.query({
			query: ({...roleSearch}) => ({
				url: `/users`,
				method: 'GET',
				params: { ...roleSearch},
				mode: 'cors',
			}),
			providesTags: (result) => 
			result
			? [
				...result.map(({ id }: {id: string|number}) => ({
					type: 'Users' as const,
					id,
				})),
				{ type: 'Users', id: 'LIST' },
			  ]
          	: [{ type: 'Users', id: 'LIST' }]
			,
			keepUnusedDataFor: 5,
		}),
		getUser: builder.query({
			query: (id) => ({
				url: `/users/${id}`,
				method: 'GET',
				mode: 'cors',
			}),
			providesTags: (result, error, id) => [{type: 'VpnAccounts', id}],
			keepUnusedDataFor: 5,
		}),
		create: builder.mutation({
			query: (body: GlobalTypes.AdminInitialValues) => ({
				url: '/users',
				method: 'POST',
				body: { ...body },
				mode: 'cors',
			}),
			invalidatesTags: ['Users'],
		}),
		reset: builder.mutation({
			query: ({id, body}: {id: string|undefined, body: {password:string|undefined; confirmPassword:string|undefined}}) => ({
				url: `/users/${id}/auth/reset-password`,
				method: 'PUT',
				body: { ...body },
				mode: 'cors',
			}),
		}),
		status: builder.mutation({
			query: ({id, status}: {id: string|undefined, status: string|undefined}) => ({
				url: `/users/${id}/status/${status}`,
				method: 'PUT',
				mode: 'cors',
			}),
			transformResponse: (response: { data: GlobalTypes.BackofficeUsers }, meta, arg) => response,
			invalidatesTags: (result, error, { id }) =>
			result
				? [
					{ type: 'Users', id },
					{ type: 'Users', id: 'LIST' },
				  ]
				: [{ type: 'Users', id: 'LIST' }],
		}),
		role: builder.mutation({
			query: ({id, role}: {id: string|undefined, role: string|undefined}) => ({
				url: `/users/${id}/role/${role}`,
				method: 'PUT',
				mode: 'cors',
			}),
			transformResponse: (response: { data: GlobalTypes.BackofficeUsers }, meta, arg) => response,
			invalidatesTags: (result, error, { id }) =>
			result
				? [
					{ type: 'Users', id },
					{ type: 'Users', id: 'LIST' },
				  ]
				: [{ type: 'Users', id: 'LIST' }],
		}),
	}),
});


export const {
	useGetUsersQuery,
	useGetUserQuery,
	useCreateMutation,
	useResetMutation,
	useStatusMutation, //enabled, disabled, blocked
	useRoleMutation //Admin, Operator
} = usersApiSlice;