import { createSlice } from '@reduxjs/toolkit';
import { RootState } from 'src/app/store';

const vpnAccountsSlice = createSlice({
	name: 'vpnAccounts',
	initialState: {
		allAccounts: [],
		allAccountsUsers: [],
		allAccountUsersPage: 1,
		detailAccountUser: {
			id: "",
			email: "",
			passCode: "",
			accountId: "",
			AccountStatus: "",
			status: "",
			createdAt: "",
			updatedAt: "",
			authTypes: {
				password: false,
				anonymous: false,
				apple: false,
				google: false
			},
			plan: {
				id: '',
				accountId: '',
				plan: '',
				price: 0,
				currencyCode: '',
				planDuration: '',
				start: '',
				end: '',
				status: '',
				createdAt: '',
				updatedAt: '',
				createdBy: '',
				updatedBy: '',
			}
		},
		detailAccount: {
			createdAt: "",
			createdBy: "",
			email: "",
			id: "",
			passCode: "",
			plan: "",
			planDuration: "",
			status: "",
			updatedAt: "",
			users: [
				{
					id: '',
					status: '',
					email: '',
					authTypes: {
						password: false,
						anonymous: false,
						apple: false,
						google: false
					},
				}
			],
			plans: [
				{
					id: '',
					accountId: '',
					plan: '',
					price: 0,
					currencyCode: '',
					planDuration: '',
					start: '',
					end: '',
					status: '',
					createdAt: '',
					updatedAt: '',
					createdBy: '',
					updatedBy: '',
				}
			],  
		},
	},
	reducers: {
		setAllAccounts: (state, action) => {
			state.allAccounts = action.payload;
		},
		setAllAccountsUsers: (state, action) => {
			state.allAccountsUsers = action.payload;
		},
		setDetailAccount: (state, action) => {
			state.detailAccount = {...action.payload};
		},
		setDetailAccountUser: (state, action) => {
			state.detailAccountUser = {...action.payload};
		},
		setDetailAccountUserStatus: (state, action) => {
			state.detailAccountUser.AccountStatus = action.payload;
		},
		setDetailAccountUserPlan: (state, action) => {
			let plans = [...action.payload]
			state.detailAccountUser.plan = plans[0];
		},
		setUserListPage: (state, action) => {
			console.log("USER LIST PAGE", action.payload)
			state.allAccountUsersPage = action.payload;
		},
	},
});

export const { setAllAccounts, setDetailAccount, setAllAccountsUsers, setDetailAccountUser, setDetailAccountUserPlan, setUserListPage, setDetailAccountUserStatus } = vpnAccountsSlice.actions;

export default vpnAccountsSlice.reducer;

export const selectAllAccounts = (state: RootState) => state.vpnAccounts.allAccounts;
export const selectAllAccountsUsers = (state: RootState) => state.vpnAccounts.allAccountsUsers;
export const selectDetailAccountUser = (state: RootState) => state.vpnAccounts.detailAccountUser;
export const selectDetailAccount = (state: RootState) => state.vpnAccounts.detailAccount;
export const selectUserListPage = (state: RootState) => state.vpnAccounts.allAccountUsersPage;
