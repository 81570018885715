import React from "react"

interface SpinnerProps {
    size: string
}

const Spinner:React.FC<SpinnerProps> = ({size}) => {
    return (
        <div className="spinner-screen">
            <div className={`lds-dual-ring-${size}`}></div>
        </div>
    )
}

export default Spinner