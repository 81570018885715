import React, { useState, useLayoutEffect } from "react"
import { createPortal } from 'react-dom';
import { createWrapperAndAppendToBody } from "src/utils/createWrapper"


interface PortalProps {
    children: JSX.Element;
    wrapperId: string
}

const ReactPortal: React.FC<PortalProps> = ({ children, wrapperId = "portal-root" }) => {
const [wrapperElement, setWrapperElement] = useState<HTMLElement | null>(null);

  useLayoutEffect(() => {
    let element = document.getElementById(wrapperId);
    let systemCreated = false;
    // if element is not found with wrapperId or wrapperId is not provided,
    // create and append to body
    if (!element) {
      systemCreated = true;
      element = createWrapperAndAppendToBody(wrapperId);
    }
    setWrapperElement(element);

    return () => {
        // delete the programatically created element
        if (systemCreated && element?.parentNode) {
          element.parentNode.removeChild(element);
        }
      }
  }, [wrapperId]);

  if (wrapperElement === null) return null;

  return createPortal(children, document.getElementById(wrapperId)!);
}

export default ReactPortal;