import { useLocation } from 'react-router-dom';
import React from 'react';

interface ActiveLinkProps {
    path: string;
    children: JSX.Element,
}

const ActiveNav: React.FC<ActiveLinkProps> = ({ path, children }) => {
	const location = useLocation();
	const className =
    location.pathname === path
			? 'dropdown-link active'
			: 'dropdown-link';
	return <div className={className}>{children}</div>;
};

export default ActiveNav;