import React from 'react';
import ReactDOM from 'react-dom/client';
import { HashRouter as Router } from 'react-router-dom';
import { Provider } from 'react-redux';
import { store } from './app/store';
import { Toaster } from 'react-hot-toast';

import './style/global.css';
import './style/vpnDetail.css'
import './style/notFound.css'
import './style/pagination.css'
import './components/shared/spinner/index.css'
import './components/shared/navbar/index.css';
import './components/form/login/index.css';
import './components/form/create/index.css'
import './components/common/UserListItem/index.css';
import './components/shared/filters/index.css';
import './components/shared/adminbar/index.css'
import './components/shared/modal/index.css'
import './components/common/ChangeStatusBackofficeUser/index.css'
import './components/shared/pagination/index.css'
import './components/shared/DateRangePicker/index.css'

import 'bootstrap/dist/css/bootstrap.min.css';

import App from './App';

const root = ReactDOM.createRoot(
	document.getElementById('root') as HTMLElement
);

root.render(
	<React.StrictMode>
		<Toaster position="top-center" reverseOrder={false} />
		<Provider store={store}>
			<Router>
				<App />
			</Router>
		</Provider>
	</React.StrictMode>
);
