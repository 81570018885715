import React, {useState} from "react"

import SelectStatus from 'src/components/shared/filters/SelectStatus';
import SearchInput from 'src/components/shared/filters/SearchInput';
import SelectAuthType from 'src/components/shared/filters/SelectAuthType';
import DateRangePicker from "src/components/shared/DateRangePicker"
import { GoSettings } from 'react-icons/go';

interface FiltersProps {
	onStatusChange(e: Array<string>): void;
	onAuthTypeChange(e: Array<string>): void;
	onSearch(searchValues: {[key:string]: string}): any;
    handleCloseFilters: () => void;
    onDateRangeFilter: (fromDate: string, toDate: string) => void
}

const Filters: React.FC<FiltersProps> = ({onStatusChange, onSearch, onAuthTypeChange, handleCloseFilters, onDateRangeFilter}) => {

    const [open, setOpen] = useState(false)

    const onCloseFilters = () => {
        handleCloseFilters()
        setOpen(false)
    }

    return (
        <div className={`filters-expand ${open ? 'open' : 'close'}`}>
            <div className="filters-row">
                <div>
                    <SearchInput onSearch={onSearch}  />
                </div>
                <button onClick={() => setOpen(true)} className={`open-filters ${open && 'open'}`}>     
                    <GoSettings className="r-m-2" />
                    Filters
                </button>
                <button onClick={() => onCloseFilters()} className={`reset-filters ${open && 'open'}`}>     
                    <GoSettings className="r-m-2" />
                    Reset
                </button>
            </div>
            <div className="dropdown-filters">
                <div className="d-flex align-items-center">
                    <label className="blue-text">Status:</label>
                    <SelectStatus
                        onChange={onStatusChange}
                    />
                </div>
                <div className="d-flex align-items-center">
                    <label className="blue-text">Auth Types:</label>
                    <SelectAuthType
                    onChange={onAuthTypeChange}
                />
                </div>
                <div className="d-flex align-items-center">
                    <label className="blue-text">Date Range:</label>
                    <DateRangePicker onDateRangeFilter={onDateRangeFilter} />
                </div>
                
            </div>
        </div>
    )
}

export default Filters