import React, {useState, useEffect} from "react"
import { AiOutlineArrowLeft, AiOutlineArrowRight } from 'react-icons/ai';
import { pagination } from "src/utils/paginationOptions"
import { Enum } from "src/enum/numberOfRec"
import { GlobalTypes } from "src/utils/types";
import {useAppDispatch} from "src/app/store"
import { setUserListPage } from 'src/features/vpnAccounts/vpnAccountsSlice';

interface PaginationProps {
	setPage: React.Dispatch<React.SetStateAction<number>> ;
	page: number;
    queryRequest: string;
    sendParams: GlobalTypes.UsersListParams;
    onPageNext: () => void;
    onPagePrev: () => void
}

const Pagination: React.FC<PaginationProps> = ({page, setPage, queryRequest, sendParams, onPageNext, onPagePrev}) => {
    const [pagesCount, setPagesCount] = useState(0)
    const dispatch = useAppDispatch()
    
    const request = pagination.options[queryRequest as keyof typeof pagination.options]

    const {
		data: list,
		isLoading,
		isSuccess,
		isError,
		error,
	} = request({...sendParams});

    useEffect(() => {
        if(isSuccess && list) {
            setPagesCount(Math.ceil(list?.count / Enum['numberOfRec']))
        }
    }, [list, isSuccess])
    
    //create array of pagescount length
    let intArr = new Array(pagesCount).fill(null).map((_, i) => i + 1);

    return (
        <div className="d-flex align-items-center justify-content-between">
            <div>
                <span className="mx-2">showing page {page} of {pagesCount} for {list?.count} entries</span>
            </div>
            <div className="pagination">
                <div
                    className={`page-button ${
                        page === 1 ? 'd-none' : ''
                    }`}
                    onClick={() => onPagePrev()}
                >
                    <AiOutlineArrowLeft />
                </div>
                <ul className="all-pages-list">
                    {intArr.map((e, i) => {
                        return <li key={i}><button className={`current-page-btn ${i + 1 === page ? "active" : ''}`} onClick={() => {
                            setPage(i + 1)
                            dispatch(setUserListPage(i + 1))
                        }
                        }>{i + 1}</button></li>
                    })}
                </ul>
                <div
                    className={`page-button ${
                        page === pagesCount ? 'd-none' : ''
                    }`}
                    onClick={() => onPageNext()}
                >
                    <AiOutlineArrowRight />
                </div>
            </div>
        </div>
    )
}

export default Pagination