import React from 'react';
import { setDetailAccountUser } from 'src/features/vpnAccounts/vpnAccountsSlice';
import { useAppDispatch } from 'src/app/store';
import { useNavigate } from 'react-router-dom';
import { GlobalTypes } from 'src/utils/types';
import { BsApple } from 'react-icons/bs';
import { FcGoogle } from 'react-icons/fc';
import { FaIdCard } from 'react-icons/fa';
import { RiBarcodeLine } from 'react-icons/ri';
import randomcolor from "randomcolor"


interface UserListItemProps {
	user: GlobalTypes.SingleAccountUser;
}

const UsersListItem: React.FC<UserListItemProps> = ({ user }) => {
	let bgColor = randomcolor({luminosity:"light", format: 'rgba', alpha: 0.6});
	let letterColor = randomcolor({luminosity:"dark"})
	let content;
	let createdAt = new Date(user.createdAt)

	const navigate = useNavigate();
	const dispatch = useAppDispatch();

	const onDetailClick = () => {
		dispatch(setDetailAccountUser(user))
		navigate(`/vpns/accounts/user/${user.id}`)
	}

	const renderEmailOrPasscode = () => {
		let value = ""

		if(user?.email) {
			value = user.email
		}

		if(!user?.email && user.passCode) {
			value = user.passCode
		}

		return value
	}


	content = (
		<tr className="list-block">
			<td className="list-block-container details" onClick={() => onDetailClick()}>
				{' '}
				<div className='user-image-container' style={{backgroundColor: bgColor, color: letterColor}}>{user?.email.slice(0,1) || "P"}</div>{renderEmailOrPasscode()}
			</td>
			<td className="list-block-size">{createdAt.toLocaleDateString('en-CA', {timeZone: 'UTC'})}</td>
			<td className="list-block-size"><span className={`status ${user.AccountStatus}`}>{user.AccountStatus}</span></td>
			<td className="list-block-size">{user.authTypes['google'] && <FcGoogle className='auth-type-icons' />} {user.authTypes['anonymous'] && <RiBarcodeLine className='auth-type-icons' />} {user.authTypes['google'] && <BsApple className='auth-type-icons' />} {user.authTypes['password'] && <FaIdCard className='auth-type-icons' />}</td>
			<td>
				<button className="status detail" onClick={() => onDetailClick()}>
					Details
				</button>
			</td>
		</tr>
	);

	return content;
};

export default UsersListItem;
