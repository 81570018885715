const model = {
	formId: 'login',
	formField: {
		username: {
			name: 'username',
			label: 'Username',
			requireErrorMsg: 'Username is required',
			value: '',
		},
		password: {
			name: 'password',
			label: 'Password',
			requireErrorMsg: 'Password is required',
			value: '',
		},
	},
};

export default model;
