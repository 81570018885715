import React from 'react';
import Select from 'react-select';
import { Options } from 'src/utils/filterSelectValues';

interface StatusProps {
	onChange(e: Array<string>): void;
}

const SelectStatus: React.FC<StatusProps> = ({ onChange }) => {
	return (
		<div className='mx-2'>
			<Select
			isMulti
			options={Options.statusValues}
			placeholder={<div style={{fontSize: 14, width: 50}}>...</div>}
			onChange={(e) => {
				let statusValues = e.map(function(item) {
					return item['value' as keyof typeof item];
				  });
				onChange(statusValues)
			}}
			/>
		</div>
	);
};

export default SelectStatus;
