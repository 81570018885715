const ListHeader = () => {
	return (
		<thead>
			<tr>
				<th>Username</th>
				<th>Created at</th>
				<th>Created by</th>
				<th>Status</th>
				<th>Role</th>
				<th>Action</th>
			</tr>
		</thead>
	);
};

export default ListHeader;