const model = {
	formId: 'create',
	formField: {
		username: {
			name: 'username',
			label: 'Username',
			requireErrorMsg: 'Username is required',
			value: '',
		},
		password: {
			name: 'password',
			label: 'Password',
			requireErrorMsg: 'Password is required',
			value: '',
		},
        confirmPassword: {
			name: 'confirmPassword',
			label: 'Confirm Password',
			requireErrorMsg: 'Confirm Password is required',
			value: '',
		},
        role: {
			name: 'role',
			label: 'Role',
			requireErrorMsg: 'Role is required',
			value: '',
		},
	},
};

export default model;