import { GlobalTypes } from './types';

import chart from "src/textures/chart.svg"
import group from "src/textures/group.svg"
import personalCard from "src/textures/personalcard.svg"


const navLinks: GlobalTypes.NavLinks = {

	DROPDOWN_DASHBOARD: { 
        img: chart,
        dropdown: false,
        open: false,
        title: 'dashboard',
        link: '/dashboard',
        subLink: [
            {
                text: '',
                path: ''
            }
        ]
        
    },
    DROPDOWN_VPN_CLIENTS:{ 
        img: group,
        dropdown: false,
        open: false,
        title: 'vpn clients',
        link: '/vpns/accounts',
        subLink: [
            {
                text: '',
                path: ''
            }
        ]
    },
    ADMIN_MANAGER:{ 
        img: personalCard,
        dropdown: false,
        open: false,
        title: 'Sub-Admin ',
        link: '/users',
        subLink: [
            {
                text: '',
                path: ''
            }
        ]
    },
}

export const NavbarLinks = {
	navLinks,
};