import {useEffect} from "react"
import { useLocation, Navigate, Outlet } from 'react-router-dom';
import { useAppSelector } from 'src/app/store';
import { selectCurrentToken } from 'src/features/auth/authSlice';
import { useAppDispatch } from "src/app/store";
import { setCredentials } from "src/features/auth/authSlice";

const RequireAuth = () => {
	const access_token = useAppSelector(selectCurrentToken);
	const dispatch = useAppDispatch()
	
	const location = useLocation();

	if(localStorage.getItem('token')) {
		console.log("GETTING TOKEN FROM LOCAL STORAGE", localStorage.getItem('token'))
	}

	useEffect(() => {
		if(localStorage.getItem('token')) {
			const token = localStorage.getItem('token')
			dispatch(setCredentials({ token}))
		}
	}, [])

	
	
	return access_token ? (
		<Outlet />
	) : (
		<Navigate to="/login" state={{ from: location }} replace />
	);
};

export default RequireAuth;
